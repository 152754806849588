/* eslint-disable react-hooks/rules-of-hooks */
import styled from "styled-components"
import {BrandCard} from "./brand-card"
import {ToggleBrands} from "./toggle-brands"
import {useEffect, useState} from "react"
import {useTopReactedToBrands} from "./brandWidgetAPI"

const theme = {
  CLUEY_SIZE_MOBILE: `720px`,
  CLUEY_TEST_FINANCIER_DISPLAY: "TestFinancierDisplay",
  CLUEY_BOLD: 600,
  CLUEY_WHITE: "#fff",
  CLUEY_INTER: "'Inter', sans-serif",
  CLUEY_LIGHT: 300
}

export const TopReactedToBrands = () => {
  const [topReactedBrands, setTopReactedBrands] = useState([])
  useEffect(() => {
    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    date = new Date(date).toISOString().slice(0, 10)
    let res
    async function fetchData() {
      res = await useTopReactedToBrands({
        filter: {type: "date", comparator: ">=", field: date}
      })

      setTopReactedBrands(res)
    }
    fetchData()
  }, [])

  if (topReactedBrands.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        id="loading"
      >
        <img src={`${process.env.PUBLIC_URL}/images/loading.png`} alt="loading" id="loading-img"></img>
        Loading...
      </div>
    )
  }
  return (
    <Wrapper>
      <Container>
        <ToggleBrands type={"landing"}>
          {topReactedBrands.map((key, index) => {
            return (
              <BrandCard
                index={index}
                key={index}
                brandData={key}
                showReactionsInfo
                showReactionsActions
                dashboard={false}
                slug={key.slug}
              />
            )
          })}
        </ToggleBrands>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 48px 0;
  height: auto;
`

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  max-width: 1280px;
  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    width: 90%;
  }
`

const Subtitle = styled.h2`
  font-family: ${theme.CLUEY_TEST_FINANCIER_DISPLAY};
  font-size: 42px;
  font-weight: ${theme.CLUEY_BOLD};
  margin: 0px;
  color: ${theme.CLUEY_WHITE};
  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    width: 90%;
    font-size: 32px;
  }
`

const Text = styled.p`
  font-family: ${theme.CLUEY_INTER};
  font-size: 16px;
  margin: ${(props) => props.margin || "6px 0"};
  line-height: 1.45;
  color: ${theme.CLUEY_WHITE};
  text-align: center;
  font-weight: ${theme.CLUEY_LIGHT};
  margin: 25px 0;
  width: 90%;
`

const TextBold = styled.span`
  font-weight: 600;
`
