import {useState} from "react"
import styled from "styled-components"

const theme = {
  CLUEY_WHITE: "#fff",
  CLUEY_SIZE_TABLET: `920px`,
  CLUEY_DARK_GREY: "#828282"
}

export const ToggleBrands = ({children, type}) => {
  const [seeMore, setSeeMore] = useState("556px")
  const [dropdownActive, setDropdownActive] = useState(false)
  const handleSeeMore = () => {
    setDropdownActive((prevState) => !prevState)
    seeMore !== "auto" ? setSeeMore("auto") : setSeeMore("556px")

    if (seeMore === "auto") {
      document.getElementById("seeBtn").scrollIntoView({behavior: "smooth"})
    }
  }

  const landingType = type === "landing"

  return (
    <>
      <Wrapper height={seeMore} borderRadius={landingType} id="seeBtn">
        {children}
      </Wrapper>
      <Column>
        <Row align="center">
          <Text
            id={seeMore === "556px" ? "see-more" : "see-less"}
            fontSize={landingType ? "16px" : "12px"}
            color={
              landingType ? `${theme.CLUEY_WHITE}` : `${theme.CLUEY_DARK_GREY}`
            }
            textAlign={"center"}
          >
            {seeMore === "556px" ? "See More" : "See Less"}
          </Text>
          <DropdownChevron
            chevronOpen={dropdownActive}
            handler={handleSeeMore}
            white={type === "landing"}
          />
        </Row>
      </Column>
    </>
  )
}

const DropdownChevron = ({
  chevronOpen,
  handler,
  large = false,
  small = false,
  white = false
}) => {
  const imageSize = large ? 15 : small ? 8 : 12

  return (
    <ChevronWrapper
      chevronOpen={chevronOpen}
      style={{marginLeft: 10}}
      onClick={handler}
    >
      <img
        id="dropdown-chevron"
        src={`${process.env.PUBLIC_URL}/images/cluey-chevron-down-dark.svg`}
        height={imageSize}
        width={imageSize}
        alt="chevron dropdown"
      />
    </ChevronWrapper>
  )
}

const Row = ({children, ...props}) => {
  const {
    width,
    height,
    background,
    borderRadius,
    margin,
    align,
    shadow,
    justify,
    bgHover,
    borderBottomR,
    borderBottomL,
    flexWrap,
    gap,
    overFlowX,
    overFlowY,
    display,
    mobileDisplay,
    id
  } = props

  return (
    <RowDiv
      width={width}
      height={height}
      align={align}
      borderRadius={borderRadius}
      margin={margin}
      shadow={shadow}
      background={background}
      justify={justify}
      bgHover={bgHover}
      borderBottomR={borderBottomR}
      borderBottomL={borderBottomL}
      flexWrap={flexWrap}
      gap={gap}
      overFlowX={overFlowX}
      overFlowY={overFlowY}
      display={display}
      mobileDisplay={mobileDisplay}
    >
      {children}
    </RowDiv>
  )
}

const Column = ({children, ...props}) => {
  const {width, height, align, justify, margin} = props

  return (
    <Div
      width={width}
      height={height}
      align={align}
      justify={justify}
      margin={margin}
    >
      {children}
    </Div>
  )
}

const Text = ({children, ...props}) => {
  const {
    fontSize,
    weight,
    letterSpacing,
    textAlign,
    color,
    lineHeight,
    margin,
    recolor,
    MWidth,
    MFontSize,
    MWeight,
    MBorder,
    MHeight,
    MCursor,
    id
  } = props
  return (
    <CustomText
      fontSize={fontSize}
      weight={weight}
      letterSpacing={letterSpacing}
      textAlign={textAlign}
      // color={color}
      lineHeight={lineHeight}
      margin={margin}
      recolor={recolor}
      MWidth={MWidth}
      MFontSize={MFontSize}
      MWeight={MWeight}
      MBorder={MBorder}
      MHeight={MHeight}
      MCursor={MCursor}
      id={id}
    >
      {children}
    </CustomText>
  )
}

const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 250ms;
  transform: ${({chevronOpen}) => chevronOpen && "rotate(0deg)"};
`

const RowDiv = styled.div`
  display: ${(props) => (props.display ? "none" : "flex")};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.background};
  border-radius: ${(props) => props.borderRadius};
  align-items: ${(props) => props.align};
  box-shadow: ${(props) => props.shadow};
  justify-content: ${(props) => props.justify};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => props.gap};

  &:hover {
    background-color: ${(props) => props.bgHover};
  }
  &:last-child {
    border-bottom-left-radius: ${(props) => props.borderBottomR};
    border-bottom-right-radius: ${(props) => props.borderBottomL};
  }

  overflow-x: ${(props) => props.overFlowX};
  overflow-y: ${(props) => props.overFlowY};

  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    display: ${(props) => (props.mobileDisplay ? props.mobileDisplay : "flex")};
  }
`

const CustomText = styled.p`
  font-family: ${theme.CLUEY_INTER};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-weight: ${(props) => props.weight};
  letter-spacing: ${(props) => props.letterSpacing};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
  margin: ${(props) => props.margin};
  color: ${(props) => (props.recolor ? `${theme.CLUEY_LINK_GREEN}` : " ")};
  id: ${(props) => props.id};
  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    font-size: ${(props) => props.MFontSize};
    font-weight: ${(props) => props.MWeight};
    height: ${(props) => props.MHeight};
    border-bottom: ${(props) => props.MBorder};
    width: ${(props) => props.MWidth};
    cursor: ${(props) => props.MCursor};
  }
  @media (max-width: 543px) {
    width: ${(props) => props.MWidth};
  }
`

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  margin: ${(props) => props.margin};
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.CLUEY_WHITE};
  height: ${(props) => props.height};
  transition: height 1s ease-out;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: ${({borderRadius}) => borderRadius && "20px"};
  background: ${theme.CLUEY_WHITE};
  @media (max-width: ${theme.CLUEY_SIZE_TABLET}) {
    height: auto;
  }
`
