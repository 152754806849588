import {TopReactedToBrands} from "./top-reacted-to-brands"

function App() {
  return (
    <div>
      <TopReactedToBrands />
    </div>
  )
}

export default App
