import {gql, ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"

// API connection
const API_ENDPOINT = "https://api.clueyconsumer.com/graphql"

const httpLink = createHttpLink({
  uri: API_ENDPOINT
})

const authLink = setContext((_, {headers}) => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("authorization")
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export async function useTopReactedToBrands({filter}) {
  const {type, comparator, field} = filter
  const {data} = await client.query({
    query: gql`
        query {
          topReactedToBrands(filter: {type: "${type}", comparator:"${comparator}", field: "${field}"}) {
            id
            name
            type
            slug
            brand_sub_type
            brand_url
            brand_logo_url
            reactions {
              up_votes
              down_votes
            }
            reactions_count
            brand_tags {
              id
              tag_name
              color_tag
            }
            people_score
            planet_score
            political_impact{
              indivs_dems
              indivs_repubs
              pacs_dems
              pacs_repubs
              total
              democratic_total
              republican_total
            }
          }
        }
      `
  })
  return data.topReactedToBrands
}
