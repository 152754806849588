import styled, {css} from "styled-components"
import React, {useState, useEffect} from "react"

const theme = {
  CLUEY_SIZE_TABLET: `920px`,
  CLUEY_SIZE_TABLET_SCREEN: "480px",
  CLUEY_SIZE_LARGE_SCREEN: "1024px",
  CLUEY_SIZE_MOBILE_LG: `1275px`,
  CLUEY_SIZE_BREAK_POINT: `1400px`,
  CLUEY_SIZE_MOBILE: `720px`,
  CLUEY_BAR_RED: "#f35757",
  CLUEY_WHITE: "#fff",
  CLUEY_INTER: "'Inter', sans-serif",
  CLUEY_SIZE_MOBILE_SCREEN: "320px",
  CLUEY_SIZE_SMALL_SCREEN: "768px",
  CLUEY_SIZE_EXTRA_LARGE_SCREEN: "1200px",
  CLUEY_BAR_BLUE: "#4778f4",
  CLUEY_DARK_GREY: "#828282",
  CLUEY_SIZE_FULL_MOBILE: `450px`,
  CLUEY_GREY: "#bdbdbd",
  CLUEY_MEDIUM_DARK_GREY: "#333333",
  CLUEY_SEMI_BOLD: 500,
  CLUEY_LIGHT_GREY: "#b3b3b3",
  CLUEY_MEDIUM_GREY: "#4f4f4f",
  CLUEY_BOLD: 600,
  CLUEY_DARK: "#000000"
}

export const BrandSummary = ({
  type,
  peopleImpact,
  planetImpact,
  politicalImpact,
  isBrandProfile = false,
  dashboard
}) => {
  const [seeMore, setSeeMore] = useState({type: "", show: false})
  return (
    <Wrapper>
      <Stat
        type="people"
        title="People Impact"
        score={peopleImpact}
        image={`${process.env.PUBLIC_URL}/images/cluey-people.png`}
        tooltipMessage={
          type === "currentFootprint"
            ? footprintMessages.people
            : brandMessages.people
        }
        isBrandProfile={isBrandProfile}
        dashboard={dashboard}
        clickSeeMore={setSeeMore}
        showSeeMore={seeMore.type === "people"}
      />
      <Stat
        type="planet"
        title="Planet Impact"
        score={planetImpact}
        image={`${process.env.PUBLIC_URL}/images/cluey-planet.png`}
        tooltipMessage={
          type === "currentFootprint"
            ? footprintMessages.planet
            : brandMessages.planet
        }
        isBrandProfile={isBrandProfile}
        dashboard={dashboard}
        clickSeeMore={setSeeMore}
        showSeeMore={seeMore.type === "planet"}
      />

      <Stat
        type="political"
        title="Political Impact"
        politicalImpact={politicalImpact}
        image={`${process.env.PUBLIC_URL}/images/cluey-box.png`}
        tooltipMessage={
          type === "currentFootprint"
            ? footprintMessages.political
            : brandMessages.political
        }
        isBrandProfile={isBrandProfile}
        dashboard={dashboard}
        clickSeeMore={setSeeMore}
        showSeeMore={seeMore.type === "political"}
      />
    </Wrapper>
  )
}

const Stat = ({
  title,
  image,
  score,
  type,
  tooltipMessage,
  politicalImpact,
  isBrandProfile,
  dashboard,
  clickSeeMore,
  showSeeMore
}) => {
  const [display, setDisplay] = useState(false)
  const getStatistics = () => {
    if (politicalImpact) {
      const percentageDem =
        (100 * politicalImpact.democratic_total) / politicalImpact.total
      const percentageRep =
        (100 * politicalImpact.republican_total) / politicalImpact.total
      const data = {
        percentageDem: Math.round(percentageDem),
        percentageRep: Math.round(percentageRep)
      }
      if (data.percentageDem || data.percentageRep) {
        return data
      } else return false
    }
  }

  const politicalStatistics = getStatistics()

  useEffect(() => {
    if (!showSeeMore) setDisplay(showSeeMore)
  }, [showSeeMore])

  return (
    <Column marginRight={type !== "political"} dashboard={dashboard}>
      <StatTitleContainer>
        <StatTitle>{title}</StatTitle>
      </StatTitleContainer>

      <Row>
        <StyledImg width={"25px"} height={"25px"}>
          <img src={image} width={30} height={30} layout="responsive" />
        </StyledImg>
        {type === "political" && (
          <>
            {politicalStatistics && (
              <ProgressBar
                style={{marginLeft: 10}}
                align={
                  politicalImpact && politicalStatistics.percentageRep === 100
                    ? "center"
                    : "space-between"
                }
                padding={
                  politicalImpact && politicalStatistics.percentageDem === 100
                    ? "0"
                    : "4px"
                }
                dashboard={dashboard}
              >
                <Progress
                  progress={
                    politicalImpact ? politicalStatistics?.percentageDem : 50
                  }
                  padding={politicalStatistics.percentageDem > 0 ? "4px" : "0"}
                  align={
                    politicalImpact && politicalStatistics.percentageDem === 100
                      ? "center"
                      : "left"
                  }
                  radius={
                    politicalImpact && politicalStatistics.percentageDem === 100
                      ? "4px"
                      : "0"
                  }
                >
                  {politicalImpact && politicalStatistics.percentageDem > 0
                    ? `${politicalStatistics?.percentageDem}%`
                    : ""}
                </Progress>
                {politicalImpact && politicalStatistics.percentageRep > 0
                  ? `${politicalStatistics?.percentageRep}%`
                  : ""}
              </ProgressBar>
            )}
            {!politicalStatistics && (
              <StyledImg width={"70px"} height={"28px"}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/no-contributions.png`}
                  width={70}
                  height={28}
                  layout="intrinsic"
                  alt="no contributions"
                />
              </StyledImg>
            )}
          </>
        )}
        {type !== "political" && (
          <StatScoreText unrated={!score} style={{marginLeft: 10}}>
            {score || (
              <StyledImg width={"40px"} height={"40px"}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/no-yet-rated.png`}
                  width={40}
                  height={40}
                  layout="intrinsic"
                  alt="not yet rated"
                />
              </StyledImg>
            )}
          </StatScoreText>
        )}
      </Row>
      {isBrandProfile && display && (
        <>
          {type === "people" && showSeeMore ? (
            <PeopleImpact show={setDisplay} />
          ) : type === "planet" && showSeeMore ? (
            <PlanetImpact show={setDisplay} />
          ) : type === "political" && showSeeMore ? (
            <PoliticalImpact
              image={image}
              politicalImpact={politicalImpact}
              show={setDisplay}
            />
          ) : null}
        </>
      )}
      {!isBrandProfile && (
        <Tooltip message={tooltipMessage} transform={"-25%"} width={"100px"}>
          <StyledImg width={"15px"} height={"15px"}>
            <img
              src={`${process.env.PUBLIC_URL}/images/cluey-question-circle.svg`}
              width={15}
              height={15}
              layout="intrinsic"
              alt="cluey question circle"
            />
          </StyledImg>
        </Tooltip>
      )}
      {isBrandProfile && (
        <SeeMoreInfo
          onClick={() => {
            setDisplay(!display)
            clickSeeMore({type: type, show: !display})
          }}
        >
          {display
            ? "see less"
            : type === "people" || type === "planet"
            ? "How We Rate"
            : "See More"}
        </SeeMoreInfo>
      )}
    </Column>
  )
}

const PeopleImpact = ({show}) => {
  return (
    <MoreInfoPeople
      onMouseLeave={() => {
        show(false)
      }}
    >
      <TextInfo>
        {`A brand’s People Impact refers to its corporate impacts on customers,
      workers, suppliers, and communities in which it operates.
      The letters are on a graded scale of A-F.
      These grades are calculated by aggregating data from multiple best-in-class third party sources like ESG ratings providers, impact assessment tools, recorded legal and regulatory violations, and more.
      In total, our sources derive their respective data from thousands of inputs.
      To better understand our ratings and data, `}
      </TextInfo>
    </MoreInfoPeople>
  )
}

const PlanetImpact = ({show}) => {
  return (
    <MoreInfoPlanet
      onMouseLeave={() => {
        show(false)
      }}
    >
      <TextInfo>
        {`A brand’s Planet Impact refers to its corporate impacts on the environment,
        like emissions, waste, and impacts on animals, ecosystems and habitats.
        The letters are on a graded scale of A-F.
        These grades are calculated by aggregating data from multiple best-in-class third party sources like ESG ratings providers,
        impact assessment tools, recorded legal and regulatory violations, and more.
        In total, our sources derive their respective data from thousands of inputs.
        To better understand our ratings and data, `}
      </TextInfo>
    </MoreInfoPlanet>
  )
}

const PoliticalImpact = ({image, politicalImpact, show}) => {
  const {indivs_dems, indivs_repubs, pacs_dems, pacs_repubs} = politicalImpact

  const demsIndivsParsed = parseInt(indivs_dems)
  const repIndivsParsed = parseInt(indivs_repubs)
  const demsPacsParsed = parseInt(pacs_dems)
  const repPacsParsed = parseInt(pacs_repubs)
  const totalIndivs = demsIndivsParsed + repIndivsParsed
  const totalPacs = demsPacsParsed + repPacsParsed
  const percentageDemIndivs = Math.round((100 * demsIndivsParsed) / totalIndivs)
  const percentageRepIndivs = Math.round((100 * repIndivsParsed) / totalIndivs)
  const percentageDemPacs = Math.round((100 * demsPacsParsed) / totalPacs)
  const percentageRepPacs = Math.round((100 * repPacsParsed) / totalPacs)

  const breakdownData = [
    {
      text: "Individual Contributions",
      totalInt: totalIndivs,
      contribution: `$${new Intl.NumberFormat("es-MX").format(totalIndivs)}`,
      percentageRep: percentageRepIndivs,
      percentageDem: percentageDemIndivs
    },
    {
      text: "PAC Contributions",
      totalInt: totalPacs,
      contribution: `$${new Intl.NumberFormat("es-MX").format(totalPacs)}`,
      percentageRep: percentageRepPacs,
      percentageDem: percentageDemPacs
    }
  ]

  return (
    <MoreInfoPolitical
      onMouseLeave={() => {
        show(false)
      }}
    >
      <TextInfo>
        Data provided by the center for responsive politics. Below are
        cumulative totals of two types of contributions to candidates of either
        malor political party in the last three U.S. federal election cycles:
        from a company's employees that exceed $1k (typically from high level
        executives), and from a company's corporate PAC. to learn more about our
        data partners and sources,
      </TextInfo>
      {breakdownData.map(
        ({text, contribution, percentageRep, percentageDem, totalInt}) => (
          <WrapperProgress>
            <TextMoreInfoPolitical>
              {text} <span>{contribution}</span>
            </TextMoreInfoPolitical>
            {totalInt > 0 && (
              <ContainerProgress>
                <StyledImg width={"25px"} height={"25px"}>
                  <img
                    src={image}
                    width={30}
                    height={30}
                    layout="responsive"
                    alt="Political Impact"
                  />
                </StyledImg>
                <ProgressBar
                  style={{marginLeft: 10}}
                  align={percentageDem > 0 ? "space-between" : "center"}
                  padding={percentageDem === 100 ? "0" : "4px"}
                >
                  <Progress
                    progress={percentageDem}
                    padding={percentageDem > 0 ? "4px" : "0"}
                    align={percentageDem === 100 ? "center" : "left"}
                    radius={percentageDem === 100 ? "4px" : "0"}
                  >
                    {percentageDem > 0 ? `${percentageDem}%` : ""}
                  </Progress>
                  {percentageRep > 0 ? `${percentageRep}%` : ""}
                </ProgressBar>
              </ContainerProgress>
            )}
          </WrapperProgress>
        )
      )}
    </MoreInfoPolitical>
  )
}

const brandMessages = {
  people: `A brand’s People Impact refers to its corporate impacts on customers, workers, suppliers, and communities in which it operates. Click on the brand to see more, or go to ‘About Cluey’ to learn about our ratings and data.`,
  planet: `A brand’s Planet Impact refers to its corporate impacts on the environment, like emissions, waste, and impacts on animals, ecosystems and habitats. Click on the brand to see more, or go to ‘About Cluey’ to learn about our ratings and data.`,
  political: `A brand’s Political Impact refers to its corporate and individual employee political contribution history to candidates of either major U.S. political party in the last three federal elections. Click on the brand to see a more comprehensive breakdown.`
}

const footprintMessages = {
  people:
    "Your People Impact is based on the aggregated social impact of your brands.",
  planet:
    "Your Planet Impact is based on the aggregated environmental impact of your brands.",
  political:
    "Your Political Impact is based on the aggregated political contributions of your brands."
}

const Tooltip = ({message, children, ...props}) => {
  const [display, setDisplay] = useState(false)
  const {height, width, transform} = props
  return (
    <span onMouseLeave={() => setDisplay(false)}>
      {display && (
        <Bubble translate={transform}>
          <Message height={height} width={width}>
            {message}
          </Message>
        </Bubble>
      )}
      <Trigger onMouseOver={() => setDisplay(true)}>{children}</Trigger>
    </span>
  )
}

const Trigger = styled.span`
  display: flex;
  cursor: pointer;
`

const Bubble = styled.div`
  width: auto;
  height: 29px;
  border-radius: 2px;
  position: absolute;
  top: 100%;
  padding-top: 9px;
  z-index: 1000;
  transform: -10%;
  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid ${theme.CLUEY_RED};
    top: 0;
    transform: -10%;
  }
  @media (max-width: 880px) {
    transform: translate(${(props) => props.translate || "-10%"});
  }
`

const Message = styled.div`
  height: auto;
  width: ${(props) => props.width || "max-content"};
  max-width: ${(props) => !props.width && "200px"};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #3e3e3e;
  border-radius: 3px;
  color: ${theme.CLUEY_WHITE};
  font-size: 12px;
  font-weight: ${theme.CLUEY_LIGHT};
  font-family: ${theme.CLUEY_INTER};
  display: flex;
  padding: 4px 10px;
  align-items: center;
`

const TextBase = styled.p`
  font-family: ${theme.CLUEY_INTER};
  font-size: 16px;
  margin: ${(props) => props.margin || "5px 0"};
  line-height: 1.45;
  text-align: left;
  max-width: 550px;
  color: ${(props) =>
    props.white ? theme.CLUEY_WHITE : theme.CLUEY_DARK_GREEN};
  @media (max-width: ${theme.CLUEY_SIZE_BREAK_POINT}) {
    ${(props) =>
      props.cardText &&
      css`
        font-size: 13px;
        text-align: left;
      `}
    ${(props) =>
      props.center &&
      css`
        text-align: center;
      `}
  }

  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    width: 95% !important;
    flex-grow: 0;
    text-align: left;
    color: ${(props) =>
      props.white ? theme.CLUEY_WHITE : theme.CLUEY_DARK_GREEN};
    ${(props) =>
      props.cardText &&
      css`
        font-size: 13px;
        text-align: left;
      `}
    ${(props) =>
      props.center &&
      css`
        text-align: center;
      `}
  }
`

const Div = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  background: ${(props) => props.bgImage};
  margin: ${(props) => props.margin};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
  min-width: ${(props) => props.minWidth};
`

const StyledImg = ({children, ...props}) => {
  const {
    width,
    height,
    border,
    borderRadius,
    bgImage,
    margin,
    opacity,
    cursor,
    minWidth
  } = props

  return (
    <Div
      width={width}
      height={height}
      border={border}
      borderRadius={borderRadius}
      bgImage={bgImage}
      margin={margin}
      opacity={opacity}
      cursor={cursor}
      minWidth={minWidth}
    >
      {children}
    </Div>
  )
}

const Wrapper = styled.div`
  display: flex;
  @media (max-width: ${theme.CLUEY_SIZE_TABLET}) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 34px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 4px;

  @media (min-width: ${theme.CLUEY_SIZE_TABLET_SCREEN}) {
    margin-right: 25px;
  }

  @media (max-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    margin-right: 30px;
  }

  @media (min-width: ${theme.CLUEY_SIZE_MOBILE_LG}) {
    margin-right: ${(props) => (props.dashboard ? "10px" : "20px")};
  }

  @media (min-width: ${theme.CLUEY_SIZE_BREAK_POINT}) {
    margin-right: ${(props) => (props.dashboard ? "28px" : "40px")};
  }

  @media (max-width: ${theme.CLUEY_SIZE_MOBILE}) {
    margin-right: ${({marginRight}) => marginRight && "0px"};
    width: 100%;
  }
`

const ProgressBar = styled.div`
  display: flex;
  background-color: ${theme.CLUEY_BAR_RED};
  border-radius: 4px;
  align-items: center;
  padding-right: ${(props) => props.padding};
  justify-content: ${(props) => props.align};
  color: ${theme.CLUEY_WHITE};
  font-family: ${theme.CLUEY_INTER};
  font-size: 12px;

  @media (max-width: ${theme.CLUEY_SIZE_MOBILE_SCREEN}) {
    width: 98px !important;
  }

  @media (min-width: ${theme.CLUEY_SIZE_TABLET_SCREEN}) {
    width: 155px;
  }

  @media (min-width: ${theme.CLUEY_SIZE_SMALL_SCREEN}) {
    width: 155px;
  }

  @media (min-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    font-size: 11px;
    width: 130px;
  }

  @media (min-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN}) {
    width: ${(props) => (props.dashboard ? "130px" : "160px")};
  }

  @media (max-width: ${theme.CLUEY_SIZE_MOBILE_LG}) {
    width: ${(props) => (props.dashboard ? "150px" : "130px")};
  }

  @media (min-width: ${theme.CLUEY_SIZE_BREAK_POINT}) {
    width: ${(props) => (props.dashboard ? "155px" : "130px")};
  }
`

const Progress = styled.div`
  text-align: ${(props) => props.align};
  padding: 0 0 0 ${(props) => props.padding};
  color: ${theme.CLUEY_WHITE};
  font-family: ${theme.CLUEY_INTER};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: ${(props) => props.radius};
  border-bottom-right-radius: ${(props) => props.radius};
  width: ${(props) => props.progress}%;
  height: 25px;
  background-color: ${theme.CLUEY_BAR_BLUE};
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.align};
  @media (max-width: ${theme.CLUEY_SIZE_BREAK_POINT}) {
    padding: 0 0 0 ${(props) => props.padding};
    font-size: 10px;
  }
`

const StatTitleContainer = styled.div`
  display: flex;
`

const StatTitle = styled(TextBase)`
  color: ${theme.CLUEY_DARK_GREY};
  font-size: 10px;
  margin-bottom: 8px;
  width: 72px;
  @media (max-width: ${theme.CLUEY_SIZE_BREAK_POINT}) {
    font-size: 8px;
  }
  @media (max-width: ${theme.CLUEY_SIZE_FULL_MOBILE}) {
    font-size: 6px;
  }
`

const SeeMoreInfo = styled(StatTitle)`
  color: ${theme.CLUEY_GREY};
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
`

const MoreInfoPeople = styled.div`
  color: ${theme.CLUEY_MEDIUM_DARK_GREY};
  font-weight: ${theme.CLUEY_SEMI_BOLD};
  font-size: 12px;
  background: ${theme.CLUEY_WHITE};
  position: absolute;
  top: 100%;
  width: 350px;
  height: 147px;
  z-index: 1000;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 ${theme.CLUEY_LIGHT_GREY};
  font-family: ${theme.CLUEY_INTER};
  line-height: 18px;
  overflow: scroll;
  @media (max-width: 400px) {
    width: 250px;
  }
`

const MoreInfoPlanet = styled(MoreInfoPeople)`
  @media (max-width: 850px) {
    left: -50%;
  }
  @media (max-width: 440px) {
    left: -80%;
  }
`

const MoreInfoPolitical = styled(MoreInfoPeople)`
  height: 175px;
  @media (max-width: 850px) {
    left: -50%;
  }
  @media (max-width: 440px) {
    left: -80%;
  }
`
const TextInfo = styled.p`
  font-family: ${theme.CLUEY_INTER};
  color: ${theme.CLUEY_DARK_GREY};
  font-size: 9px;
  margin: 10px 0 20px;
  line-height: 12px;
  max-width: 300px;
  word-break: break-word;
`
const LinkToRatings = styled(TextInfo)`
  margin: 0 0 0 2px;
  padding: 0;
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`

const WrapperProgress = styled.div`
  margin-bottom: 10px;
`

const TextMoreInfoPolitical = styled.div`
  font-family: ${theme.CLUEY_INTER};
  color: ${theme.CLUEY_DARK_GREY};
  font-size: 9px;
  margin-bottom: 4px;
  span {
    color: ${theme.CLUEY_MEDIUM_GREY};
    font-weight: ${theme.CLUEY_BOLD};
  }
`
const ContainerProgress = styled.div`
  display: flex;
`

const StatScoreText = styled(TextBase)`
  font-size: ${(props) => (props.unrated ? "10px" : "18px")};
  color: ${theme.CLUEY_DARK};
  font-weight: ${theme.CLUEY_BOLD};
  line-height: 1;
  @media (max-width: ${theme.CLUEY_SIZE_FULL_MOBILE}) {
    font-size: ${(props) => (props.unrated ? "10px" : "14px")};
  }
`
