/* eslint-disable no-restricted-globals */
import styled from "styled-components"
import {BrandSummary} from "./brand-summary"

const theme = {
  CLUEY_INTER: "'Inter', sans-serif",
  CLUEY_BOLD: 600,
  CLUEY_WHITE: "#fff",
  CLUEY_BLACK: "#000",
  CLUEY_SIZE_LARGE_SCREEN: "1024px",
  CLUEY_SIZE_EXTRA_LARGE_SCREEN: "1200px",
  CLUEY_SIZE_MOBILE_LG: `1275px`,
  CLUEY_SIZE_EXTRA_LARGE_SCREEN_MAX: "1600px",
  CLUEY_GRAY: "#e1e1e1",
  CLUEY_RED: "#eb5757",
  CLUEY_GREEN: "#6fcf97"
}

export const BrandCard = ({
  index,
  brandData,
  showDeleteBtn = false,
  showReactionsInfo = false,
  showReactionsActions = true,
  mutateHandler,
  dashboard,
  onClick,
  slug
}) => {
  if (!brandData) return null

  const Reaction = ({reaction, size}) => {
    const reactionUp = "up"
    const reactionDown = "down"

    if (showReactionsInfo) {
      return (
        <div>
          {reaction === reactionUp ? (
            <Votes color={theme.CLUEY_GREEN}>{`+${
              brandData.reactions ? brandData.reactions.up_votes : 0
            }`}</Votes>
          ) : (
            <Votes color={theme.CLUEY_RED}>{`+${
              brandData.reactions ? brandData.reactions.down_votes : 0
            }`}</Votes>
          )}
          <img
            priority
            src={
              reaction === reactionUp
                ? `${process.env.PUBLIC_URL}/images/thumbsup.png`
                : `${process.env.PUBLIC_URL}/images/thumbsdown.png`
            }
            width={size}
            height={size}
            layout="fixed"
            alt="thumbs up and thumbs down"
          />
        </div>
      )
    }
  }

  return (
    <Wrapper
      onClick={
        // parent.location is needed for navigation for embed/iframe objects to top most frame
        // eslint-disable-next-line no-restricted-globals
        () => (parent.location = `https://app.clueyconsumer.com/brands/${slug}`)
      }
    >
      {index >= 0 && <Index>{index + 1}</Index>}
      <Container dashboard={dashboard}>
        <Column>
          {showReactionsActions && (
            <Actions>
              <Reaction reaction="up" size={18} />
              <Reaction reaction="down" size={18} />
              {showDeleteBtn && !showReactionsInfo && (
                <Reaction reaction="close" size={14} />
              )}
            </Actions>
          )}

          <Brand>
            <img src={brandData.brand_logo_url} alt="" />
            <Line />
            <p>{brandData.name}</p>
          </Brand>
        </Column>

        <Column>
          <TagsContainer>
            {brandData.brand_tags &&
              brandData.brand_tags.length > 0 &&
              brandData.brand_tags.map((key, index) => {
                const {white, toolTip} = tagData(key.tag_name)
                return (
                  <div>
                    <Tag
                      key={index}
                      white={white}
                      backgroundColor={
                        key.color_tag ? key.color_tag : "#dcfffd"
                      }
                    >
                      {key.tag_name}
                    </Tag>
                  </div>
                )
              })}
          </TagsContainer>
        </Column>
        <Column>
          <BrandSummary
            peopleImpact={brandData.people_score}
            planetImpact={brandData.planet_score}
            politicalImpact={brandData.political_impact}
            dashboard={dashboard}
          />
        </Column>
      </Container>
    </Wrapper>
  )
}

const tagData = (tagName) => {
  let data = {}
  switch (tagName) {
    case "Civic Alliance Member":
      data = {
        white: true,
        toolTip:
          "Members are committed to increasing civic engagement by empowering U.S employees with resources and workplace policies to participate civically and work towards higher voter turnout."
      }
      break
    case "RE100 Member":
      data = {
        white: true,
        toolTip:
          "Members are committed to driving transition to 100% renewable electricity and must meet joining and technical criteria."
      }
      break
    case "Best Place to Work for LGBTQ Equality":
      data = {
        white: false,
        toolTip:
          "Recognized by the Human Rights Campaign (HRC) as a company that has taken concrete steps to establish and implement comprehensive policies, benefits and practices that ensure greater equity for LGBTQ workers and their families."
      }
      break
    case "Employee Ownership":
      data = {
        white: false,
        toolTip:
          "These Companies offer an employee stock ownership plan (ESOP) as a method to provide the company’s workforce with an ownership interest in the company."
      }
      break
    case "B Corp Certified":
      data = {
        white: false,
        toolTip:
          "B Corp certification proves a business meets the highest standards for verified social and environmental performance, public transparency, and legal accountability to balance profit and purpose."
      }
      break
    case "Responsible Business Alliance Member":
      data = {
        white: false,
        toolTip:
          "Members are committed to creating shared value for businesses, workers and communities and must meet membership requirements."
      }
      break
    default:
      data = {}
      break
  }
  return data
}

const Tag = styled.div`
  font-size: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 4px;
  display: inline-block;
  font-family: ${theme.CLUEY_INTER};
  font-weight: ${theme.CLUEY_BOLD};
  background-color: ${({backgroundColor}) => backgroundColor};
  text-transform: uppercase;
  color: ${({white}) =>
    white ? `${theme.CLUEY_WHITE}` : `${theme.CLUEY_BLACK}`};
`

const Wrapper = styled.div`
  border-top: 1px solid ${theme.CLUEY_GRAY};
  width: 100%;
  cursor: pointer;
  display: flex;
  font-family: ${theme.CLUEY_INTER};
  transition: 250ms;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  &:first-child {
    border-top: 0px solid transparent;
  }
`

const Index = styled.div`
  border-right: 1px solid ${theme.CLUEY_GRAY};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`

const Container = styled.div`
  display: grid;
  padding: 5px;
  width: 100%;
  @media (min-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    grid-template-columns: ${(props) =>
      props.dashboard ? "225px 20% 3fr" : "225px 20% 3fr"};
  }
  @media (min-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN}) {
    grid-template-columns: ${(props) =>
      props.dashboard ? "235px 30% 3fr" : "1fr 25% 2fr"};
  }
  @media (min-width: ${theme.CLUEY_SIZE_MOBILE_LG}) {
    grid-template-columns: ${(props) =>
      props.dashboard ? "230px 18% 3fr" : "1fr 30% 2fr"};
  }
  @media (min-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN_MAX}) {
    grid-template-columns: ${(props) =>
      props.dashboard ? "235px 28% 3fr" : "1fr 32% 2fr"};
  }
`

const Column = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    justify-content: space-evenly;
    &:nth-child(2) {
      border-left: 1px solid ${theme.CLUEY_GRAY};
    }
  }
  @media (min-width: ${theme.CLUEY_SIZE_LARGE_SCREEN} and max-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN}) {
    justify-content: center;
    &:last-child {
      grid-column: 1 / 3;
      border-top: 1px solid ${theme.CLUEY_GRAY};
      margin-top: 10px;
      padding-top: 20px;
    }
  }
  @media (max-width: 920px) {
    &:nth-child(1) {
      flex-direction: row-reverse;
    }
    &:nth-child(2) {
      width: 100%;
      order: 3;
      border-left: 0;
      border-top: 1px solid ${theme.CLUEY_GRAY};
      margin-top: 14px;
      padding-top: 14px;
    }
  }
`
const Brand = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  img {
    width: 60px;
  }
  @media (max-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    justify-content: start;
    p {
      margin-left: 20px;
    }
  }
  @media (max-width: 920px) {
    justify-content: start;
  }
`
const Actions = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 10px;
  @media (max-width: ${theme.CLUEY_SIZE_LARGE_SCREEN}) {
    width: 100px;
    flex-direction: row;
    height: auto;
  }
`

const Votes = styled.p`
  margin: 0;
  color: ${({color}) => color};
  font-size: 11px;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  @media (max-width: 1640px) {
    display: block;
  }
  @media (max-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN}) {
    display: flex;
  }
`

const Line = styled.div`
  background-color: ${theme.CLUEY_GRAY};
  width: 1px;
  height: 80px;
  margin: 0 15px;
  @media (max-width: ${theme.CLUEY_SIZE_EXTRA_LARGE_SCREEN}) {
    display: none;
  }
`
